"use client";
import React, { useState, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { toolsList } from "../data/toolList";
import { Settings, ChevronRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose,
} from "@/components/ui/sheet";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";

const ExploreTools: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const filteredTools = useMemo(() => 
    toolsList.filter((tool) =>
      tool.name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [searchTerm]
  );

  const toolCount = toolsList.length;

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button variant="secondary" className="text-blue-700 rounded-full bg-transparent border-2 py-3">
          <Settings className="w-5 h-5 mr-2" />
          Tools
        </Button>
      </SheetTrigger>
      <SheetContent side="bottom" className="h-[80vh] sm:h-[90vh]">
        <SheetHeader>
          <SheetTitle>Explore Tools</SheetTitle>
          <SheetDescription>
            Discover our collection of <span className="text-blue-600">{toolCount}</span> powerful tools!
          </SheetDescription>
        </SheetHeader>
        <div className="py-4">
          <Input
            type="search"
            placeholder="Search tools..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full"
          />
        </div>
        <ScrollArea className="h-[calc(100%-120px)]">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
            <AnimatePresence>
              {filteredTools.map((tool) => (
                <motion.div
                  key={tool.name}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.2 }}
                >
                  <SheetClose asChild>
                    <a
                      href={tool.link}
                      className="flex items-center p-3 rounded-lg hover:bg-blue-10 transition-colors bg-slate-50"
                    >
                      <tool.icon className="w-6 h-6 text-blue-600 mr-3 flex-shrink-0"  />
                      <div className="flex-grow">
                        <p className="font-semibold text-sm text-gray-800">
                          {tool.name}
                        </p>
                        <p className="text-xs text-gray-500 mt-1">
                          {tool.description}
                        </p>
                      </div>
                      <ChevronRight className="w-4 h-4 text-gray-400 flex-shrink-0" />
                    </a>
                  </SheetClose>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export default ExploreTools;