import { LucideIcon, QrCode ,Code, Settings, FileText, Eye, Lock, Tag, Palette, Shield, Key, FileCode, SwatchBook, FileSearch, FolderArchive, Image, SprayCan, Monitor, Shuffle, Scissors } from "lucide-react"; // Assuming you use Lucide icons

interface Tool {
    name: string;
    description: string;
    link: string;
    icon: LucideIcon; // Specify the icon type
    backgroundColor: string; // New property for background color
}

export const toolsList: Tool[] = [
    {
        name: "Color Converter",
        icon: Palette, // Palette icon for color conversion
        description: "Convert colors between HEX, RGB, RGBA, HSL, HSLA, and CMYK.",
        link: "/tools/color-converter/",
        backgroundColor: "#f0fcff", // Light cyan
    },
    {
        name: "Tailwind Color Generator",
        icon: SwatchBook, // SwatchBook icon for color generation
        description: "Generate Tailwind CSS colors with a live preview.",
        link: "/tools/tailwind-color-generator/",
        backgroundColor: "#e6f7ff", // Light blue
    },
    {
        name: "Meta Tag Analyzer",
        icon: Tag, // Tag icon for meta tag analysis
        description: "Analyze and optimize the meta tags of your webpage.",
        link: "/tools/meta-analyzer/",
        backgroundColor: "#f0f4ff", // Light blue
    },
    {
        name: "Bootstrap to Tailwind",
        icon: Code, // Code icon for conversion tools
        description: "Convert Bootstrap code to Tailwind effortlessly.",
        link: "/tools/bootstrap-to-tailwind/",
        backgroundColor: "#f5f9ff", // Light grey-blue
    },
    {
        name: "HTML to Liquid",
        icon: FileCode, // FileCode icon for file conversions
        description: "Convert HTML into Shopify Liquid templates.",
        link: "/html-to-liquid/",
        backgroundColor: "#fef9e7", // Light yellow
    },
    {
        name: "HTML/CSS Beautifier",
        icon: Code, // Code icon for beautifying tools
        description: "Beautify your HTML and CSS files instantly.",
        link: "/tools/beautify/",
        backgroundColor: "#f7fff7", // Light green
    },
    {
        name: "Image to SVG Converter",
        icon: Image,
        description: "Convert PNG and JPEG images to SVG instantly.",
        link: "https://www.infyways.com/tools/image-to-svg/",
        backgroundColor: "#f0ffe6",
    },
    {
        name: "JSON/XML Converter",
        icon: Settings, // Settings icon for data conversion tools
        description: "Convert JSON to XML and vice versa.",
        link: "/tools/json-to-xml/",
        backgroundColor: "#fffbf0", // Light orange
    },
    {
        name: "Favicon Generator",
        icon: FileCode, // FileCode icon for favicon generation
        description: "Generate favicons for your website.",
        link: "/tools/favicon-generator/",
        backgroundColor: "#fef0ff", // Light pink
    },
    {
        name: "Readability Checker",
        icon: Eye, // Eye icon for readability analysis
        description: "Analyze the readability of text or webpages.",
        link: "/tools/readability/",
        backgroundColor: "#f0f0ff", // Light purple
    },
    {
        name: "Lorem Ipsum Generator",
        icon: FileText, // FileText icon for text generation tools
        description: "Generate placeholder text for your projects.",
        link: "/tools/lorem-ipsum-generator/",
        backgroundColor: "#f0fff4", // Light mint green
    },
    {
        name: "Password Generator",
        icon: Lock, // Lock icon for password generation
        description: "Generate strong passwords and check their strength.",
        link: "/tools/password-generator/",
        backgroundColor: "#f9f0ff", // Light lavender
    },
    {
        name: "Base64 Converter",
        icon: Shield, // Shield icon for encoding/decoding tools
        description: "Encode and decode text with Base64 encoding.",
        link: "/tools/base64-converter/",
        backgroundColor: "#e8f0fe", // Light blue
    },
    {
        name: "MD5 Generator",
        icon: Key, // Key icon for hashing tools
        description: "Generate MD5 hashes from text.",
        link: "/tools/md5-generator/",
        backgroundColor: "#f5e8ff", // Light purple
    },
    {
        name: ".htaccess Generator",
        icon: FileCode, // FileCode icon for file-related tools
        description: "Generate .htaccess configurations easily.",
        link: "/tools/htaccess-generator/",
        backgroundColor: "#f2f2f0", // Light grey
    },
    {
        name: "Markdown Editor",
        icon: FileText, // FileText icon for text editing
        description: "Edit Markdown with live preview and export options.",
        link: "/tools/markdown-editor/",
        backgroundColor: "#f0faff", // Light cyan
    },
    {
        name: "HTML to Markdown Converter",
        icon: Code, // Code icon for conversion tools
        description: "Convert HTML to Markdown and vice versa.",
        link: "/tools/html-markdown-converter/",
        backgroundColor: "#e6ffe6", // Light green
    },
    {
        name: "XML Validator",
        icon: FileSearch, // FileSearch icon for XML validation tools
        description: "Validate and format your XML files easily.",
        link: "/tools/xml-validator/",
        backgroundColor: "#f0f5ff", // Light blue
    },
    {
        name: "SVG Optimizer",
        icon: FolderArchive, // FolderArchive icon for SVG optimization
        description: "Optimize and format your SVG files.",
        link: "/tools/svg-optimizer/",
        backgroundColor: "#fff0f0", // Light red
    },
    {
        name: "SVG Converter",
        icon: Image, // Image icon for SVG conversion
        description: "Convert SVG images to PNG, JPEG, WEBP, PDF, and Base64.",
        link: "/tools/svg-converter/",
        backgroundColor: "#f0ffe6", // Light green
    },
    {
        name: "Image to WebP Converter",
        icon: Image, // Image icon for image conversion
        description: "Convert PNG and JPEG images to WebP with adjustable quality and live preview.",
        link: "/tools/image-to-webp/",
        backgroundColor: "#e6faff", // Light cyan
    },
    {
        name: "Meta Data Viewer",
        icon: FileSearch, // FileSearch icon for metadata viewing
        description: "View detailed metadata of your images including EXIF data.",
        link: "/tools/metadata-viewer/",
        backgroundColor: "#f0f4f7", // Light grey-blue
    },
    {
        name: "Tailwind Gradient CSS Generator",
        icon: SprayCan, // Gradient icon for gradient generation
        description: "Generate gradients with Tailwind CSS and download as SVG.",
        link: "/tools/tailwind-gradient-generator/",
        backgroundColor: "#f0ffe6", // Light green
    },
    {
        name: "Website Responsive Checker",
        icon: Monitor, // Monitor icon for website responsiveness checking
        description: "Check how your website responds on 50+ devices including phones, tablets, and desktops.",
        link: "/tools/website-responsive-checker/",
        backgroundColor: "#f0ffe6", // Light green
    },
    {
        name: "Color Palette Extractor",
        icon: Image, // Image icon for color palette extraction
        description: "Extract color palettes from any image.",
        link: "/tools/color-palette-image/",
        backgroundColor: "#ffe6f0", // Light pink
    },
    {
        name: "Color Palette Generator",
        icon: Palette, // Palette icon for color palette generation
        description: "Generate stunning color palettes for your design projects.",
        link: "/tools/color-palette-generator/",
        backgroundColor: "#f0fce6", // Light green
    },
    {
        name: "Schema Generator",
        icon: FileCode, // FileCode icon for schema generation
        description: "Generate structured data schemas for SEO.",
        link: "/tools/schema-generator/",
        backgroundColor: "#e6f0ff", // Light blue
    },
    {
        name: "HTML to JSX Converter",
        icon: Code, // Code icon for conversion tools
        description: "Convert HTML to React JSX code effortlessly.",
        link: "/tools/html-to-jsx/",
        backgroundColor: "#f0f8ff", // Light cyan
    },
    {
        name: "CSS to Tailwind Converter",
        icon: Code, // Code icon for CSS to Tailwind conversion
        description: "Convert standard CSS to Tailwind CSS classes.",
        link: "/tools/css-to-tailwind/",
        backgroundColor: "#e6f7ff", // Light blue
    },
    {
        name: "QR Code Generator",
        icon: QrCode, // Monitor icon for QR code generation
        description: "Generate custom QR codes for any use and download them.",
        link: "/tools/qr-generator/",
        backgroundColor: "#f0faff", // Light blue
    },
    {
        name: "Random Number Generator",
        icon: Shuffle, // Suitable icon for random number generation
        description: "Generate random numbers within a customizable range with real-time updates.",
        link: "/tools/random-number-generator/",
        backgroundColor: "#fff5f0", // Light orange
    },
    {
        name: "Unused CSS Cleaner Tool",
        icon: Scissors, // Suitable icon for CSS cleanup
        description: "Automatically clean and optimize unused CSS from your website for better performance.",
        link: "/tools/unused-css/",
        backgroundColor: "#f0fff5", // Light green
    }
    
];
