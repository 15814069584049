"use client";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import Nav from "./Nav";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import ExploreTools from "../Main/ExploreTools";

export default function Header() {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        const currentScrollY = window.scrollY;
        if (currentScrollY < lastScrollY) {
          setIsVisible(true);
        } else if (currentScrollY > 100 && currentScrollY > lastScrollY) {
          setIsVisible(false);
        }
        setLastScrollY(currentScrollY);
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      // Cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <header
      className={`  bg-white  md:sticky md:top-0 md:z-50  md:transition-all md:duration-300  ${
        isVisible ? "md:translate-y-0" : "md:-translate-y-full"
      }  sm:h-[100px]   h-[60px]  relative  sm:mt-2  my-4  sm:my-0`}
    >
      <div className="mx-auto h-full container">
        <div className="grid sm:grid-cols-12 h-full items-center">
          <div className="sm:col-span-2 ">
            <Link href="/">
              <Image
                src="/images/logo.png"
                alt="logo"
                width={200}
                height={100}
                className="max-h-[80px] w-auto"
              />
            </Link>
          </div>
          <div className="sm:col-span-8 flex justify-center">
            <Nav />
          </div>
          <div className="sm:col-span-2 flex justify-end">
            <div className="hidden lg:block">
              <div className="hidden sm:flex justify-end items-center gap-3">
              <ExploreTools/>
                <Button
                  asChild
                  className="bg-white border-2 border-blue-600 text-blue-600 text-md px-6 py-3 rounded-full hover:bg-blue-600 hover:text-white transition duration-300"
                >
                  <Link href="/contact/">Contact</Link>
                </Button>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
