"use client";
import { useState } from 'react';
import { ChevronDown } from 'lucide-react'; // Import Lucide icon
import servicesData from './data/services.json'; // Import the JSON data

const FooterLinks = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFooter = () => {
    setIsOpen(!isOpen);
  };

  return (
    <footer className="">
      <div className="container mx-auto px-4">
        {/* Toggle button */}
        <div className="text-center mb-4 flex justify-end">
  <button
    onClick={toggleFooter}
    className="flex justify-center items-center"
    aria-label={isOpen ? "Collapse footer" : "Expand footer"} // Descriptive aria-label
  >
    <span className="sr-only">{isOpen ? 'Collapse' : 'Expand'}</span> {/* Hidden text for screen readers */}
    <ChevronDown
      className={`transition-transform duration-300 ${
        isOpen ? 'rotate-180' : ''
      }`}
    />
  </button>
</div>


        {/* Footer content - hidden by default, revealed when clicked */}
        <div
          className={`overflow-hidden transition-all duration-500 ${
                       isOpen ? 'h-auto max-h-[5000px]' : 'max-h-0'
          }`}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
            {servicesData.categories.map((category, index) => (
              <div key={index} className="footer-category">
                <h4 className="text-l font-semibold mb-4 text-blue-700 uppercase">
                  {category.category}
                </h4>
                <ul>
                  {category.services.map((service, serviceIndex) => (
                    <li key={serviceIndex} className="mb-2">
                      <a
                        href={service.url}
                        className="hover:text-blue-600 transition-colors text-sm "
                      >
                        {service.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterLinks;
