"use client";
import { useState } from "react";
import { Send } from "lucide-react";

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const res = await fetch("/api/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await res.json();

      if (res.ok) {
        // Set success message
        setSuccessMessage(data.message);
        setEmail(""); // Clear email field
      } else {
        // Set error message
        setErrorMessage(data.message || "An error occurred. Please try again.");
      }
    } catch (err) {
      console.error("Error subscribing:", err);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center py-6">
      <form onSubmit={handleSubmit} className="flex w-full max-w-sm">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="px-4 py-2 w-full border border-slate-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-slate-500 bg-transparent"
          required
        />
        <button
          type="submit"
          aria-label="Newsletter"
          className="px-4 py-2 bg-blue-700 text-white  rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-slate-500"
          disabled={loading}
        >
          {loading ? "Sending..." : <Send size={15} />}
        </button>
      </form>

      {successMessage && (
        <p className="mt-4 text-sm text-green-600">{successMessage}</p>
      )}
      {errorMessage && <p className="mt-4 text-red-600">{errorMessage}</p>}
    </div>
  );
}
