"use client";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
function Tawk  () {
    return (
        <TawkMessengerReact
            propertyId="561c365cf207135a361e3e8b"
            widgetId="default"
        />
    );
};  
export default Tawk;